import React from 'react';
import "./Header1.css";
import {  Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Header1() {
  return (
    <div className="header">
      {/* Logo Section */}
      <div className="logo">
        <Nav>
          {/* Navigate to home when the logo is clicked */}
          <Link to="/home">  
            <img src="/logo.jpeg" alt="Logo" className="logoImg" />
          </Link>
        </Nav>
      </div>

      {/* Navigation Menu */}
      <div className="menu">
        <Nav className="me-auto menulist">
          {/* Updated navigation links */}
          <NavLink 
            to="/findOutMore" 
            className="nav-link" 
            activeClassName="active"
          >
            Why Choose Us
          </NavLink>
          <NavLink 
            to="/services" 
            className="nav-link" 
            activeClassName="active"
          >
            Our Services
          </NavLink>
          <NavLink 
            to="/clients" 
            className="nav-link" 
            activeClassName="active"
          >
            Clients
          </NavLink>
          <NavLink 
            to="/footer" 
            className="nav-link" 
            activeClassName="active"
          >
            Contact Us
          </NavLink>
        </Nav>
      </div>
    </div>
  );
}

export default Header1;