import React from "react";
import Header1 from "./Header1";
import "./CustomSoftware.css";

const DataAnalytics = () => {
  return (
    <div className="custom-software-page">
      {/* Header Section */}
      <Header1 />
      <br/>

      {/* Blue Panel Section */}
      <div className="bluePanel">
        <div className="heading">Data Analytics and Management</div>
      </div>

      {/* Content Section */}
      <main className="content">
        <p className="intro">
          <h4><strong className="highlight">Transforming Data into Strategic Insights:</strong></h4>
          <br />
          InHawk empowers businesses with comprehensive data analytics and
          management services, helping organizations unlock the true potential
          of their data to drive informed decision-making and operational
          efficiency.
        </p>
        <section className="section">
        <strong className="highlight">Data Management Services:</strong>
          <ul>
            <li>
              Organizing and processing data from diverse sources into
              structured formats for better usability.
            </li>
            <li>
              Ensuring accuracy and completeness through meticulous quality
              control processes at every stage.
            </li>
            <li>
              Identifying patterns and trends to help businesses gain actionable
              insights for growth.
            </li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">Database Administration:</strong>
          <ul>
            <li>
              Expert management of Oracle, MySQL, and MS-SQL databases for
              secure, high-performance operations.
            </li>
            <li>
              Offering robust backup and recovery systems to safeguard critical
              business data.
            </li>
            <li>
              Transitioning legacy systems to modern platforms with minimal
              downtime and maximum efficiency.
            </li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">Document Management Systems:</strong>
          <ul>
            <li>
              Simplified systems to store, organize, and retrieve digital or
              scanned documents efficiently.
            </li>
            <li>
              User-friendly interfaces designed for seamless collaboration and
              accessibility across teams.
            </li>
            <li>
              Secure document control to maintain compliance and ensure
              operational transparency.
            </li>
          </ul>
        </section>

        {/* Back Link */}
        <a href="/Services" className="backButton">
          Back 
        </a>
      </main>
    </div>
  );
};

export default DataAnalytics;