import React from 'react';
import "./Clients.css";
import Header1 from './Header1';

function Clients() {
  return (
    <div className="clientBg">
      <Header1 />
      <div className="containerClient">
        <div className="cont">Our Valued Partners and Collaborators</div>
        <div className="logoPartner">
          <img src="/2.jpeg" alt="Logo" className="logosImg" />
          <img src="/4.jpg" alt="Logo" className="logosImg" />
          <img src="/1.jpeg" alt="Logo" className="logosImg" />
          <img src="/3.jpg" alt="Logo" className="logosImg" />
          <img src="/5.png" alt="Logo" className="logosImg" />
          <img src="/7.png" alt="Logo" className="logosImg" />
          <img src="/6.jpeg" alt="Logo" className="logosImg" />
          <img src="/9.jpeg" alt="Logo" className="logosImg" />
          <img src="/8.jpeg" alt="Logo" className="logosImg" />
          <img src="/10.png" alt="Logo" className="logosImg" />
          <img src="/12.png" alt="Logo" className="logosImg" />
          <img src="/13.jpeg" alt="Logo" className="logosImg" />
          <img src="/14.jpeg" alt="Logo" className="logosImg" />
          <img src="/17.jpeg" alt="Logo" className="logosImg" />
          <img src="/18.png" alt="Logo" className="logosImg" />
          <img src="/15.png" alt="Logo" className="logosImg" />
          <img src="/16.png" alt="Logo" className="logosImg" />
        </div>
      </div>
    </div>
  );
}

export default Clients;