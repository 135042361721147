import React from 'react';
import './Home.css';
import Header1 from './Header1';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="homePage">
      <div className="homeBg">
        <Header1 />
        <div className="in">
          <div className="floating-cont">
            <div className="title">Inhawk IT Solutions</div>
            <div className="title-body">
              At InHawk, our foundation is built on integrity, innovation, and a commitment to excellence. Since 2011, we've been delivering cutting-edge IT solutions that empower businesses and institutions to thrive in the digital age. Our culture celebrates teamwork and achievements, fostering an environment of mutual respect and inclusivity. With a steadfast focus on eliminating discrimination and promoting equality, we create value for all stakeholders while driving purposeful innovation and delivering solutions that matter.
            </div>
            <div className="find-more">
              <Link to="/findOutMore">
                <Button className="btnFind">Find out more</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;