import React from "react";
import Header1 from "./Header1";
import "./CustomSoftware.css";

const CloudTransformation = () => {
  return (
    <div className="custom-software-page">
      {/* Header Section */}
      <Header1 />
      <br/>

      {/* Blue Panel Section */}
      <div className="bluePanel">
        <div className="heading">Cloud Transformation Services</div>
      </div>

      {/* Content Section */}
      <main className="content">
        <p className="intro">
          <h4><strong className="highlight">Seamlessly Transition to Scalable Cloud Solutions</strong></h4>
          <br />
          InHawk simplifies your journey to the cloud with tailored strategies
          and innovative solutions that ensure security, scalability, and
          cost-efficiency.
        </p>
        <section className="section">
        <strong className="highlight">Cloud Adoption Strategy:</strong>
          <ul>
            <li>
              Assessing existing IT environments to identify the best-fit cloud
              integration approach.
            </li>
            <li>
              Migrating on-premises resources to cloud-based platforms while
              minimizing disruptions.
            </li>
            <li>
              Designing scalable, flexible cloud environments to meet evolving
              business requirements.
            </li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">Customized Cloud Applications:</strong>
          <ul>
            <li>
              Delivering ERP systems and business applications hosted on the
              cloud for seamless accessibility.
            </li>
            <li>
              Developing bespoke solutions tailored to educational institutions
              and enterprises.
            </li>
            <li>
              Enabling real-time data sharing and collaboration through advanced
              cloud-hosted tools.
            </li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">Cost Optimization and Maintenance:</strong>
          <ul>
            <li>
              Reducing IT costs with carefully designed cloud implementations
              tailored to budget constraints.
            </li>
            <li>
              Providing ongoing support to ensure the smooth functioning of
              cloud infrastructure.
            </li>
            <li>
              Monitoring systems to optimize performance and resolve issues
              proactively.
            </li>
          </ul>
        </section>

        {/* Back Link */}
        <a href="/Services" className="backButton">
          Back 
        </a>
      </main>
    </div>
  );
};

export default CloudTransformation;