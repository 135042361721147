import React from "react";
import Header1 from "./Header1";
import "./CustomSoftware.css";

const ITInfrastructure = () => {
  return (
    <div className="custom-software-page">
      {/* Header Section */}
      <Header1 />
      <br/>

      {/* Blue Panel Section */}
      <div className="bluePanel">
        <div className="heading">IT Infrastructure Design & Support</div>
      </div>

      {/* Content Section */}
      <main className="content">
        <p className="intro">
       <h4> <strong className="highlight">Building Robust IT Systems for High Performance:</strong></h4>
          <br />
          InHawk designs, implements, and maintains IT infrastructures tailored
          to your organization’s needs.
        </p>
        <section className="section">
          <strong className="highlight">Network Design and Implementation:</strong>
          <p>
            We provide end-to-end network solutions to enhance connectivity and
            performance.
          </p>
          <ul>
            <li>
              Active and passive component design for robust
              infrastructure.
            </li>
            <li>Campus-wide Wi-Fi solutions for seamless wireless connectivity.</li>
            <li>Fiber optic cabling for high-speed data transfers.</li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">Server Room Setup:</strong>
          <p>
            We design and set up state-of-the-art server rooms with complete
            physical and digital security.
          </p>
          <ul>
            <li>Comprehensive server installation and monitoring solutions.</li>
            <li>Security measures, including access control systems and CCTV.</li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">IT Infrastructure Audits and Maintenance:</strong>
          <p>Regular audits to ensure infrastructure security and performance.</p>
          <ul>
            <li>Hybrid solutions integrating cloud computing with on-premises systems.</li>
            <li>24/7 infrastructure monitoring and rapid troubleshooting.</li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">Disaster Recovery Support:</strong>
          <p>Backup solutions, including full, differential, and incremental backups.</p>
          <ul>
            <li>
              Testing and implementation of RTO/RPO-compliant recovery
              strategies.
            </li>
          </ul>
        </section>

        {/* Back Link */}
        <a href="/Services" className="backButton">
          Back 
        </a>
      </main>
    </div>
  );
};

export default ITInfrastructure;