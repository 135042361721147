import React from "react";
import { Link } from "react-router-dom";
import Header1 from "./Header1";
import "./CustomSoftware.css";

const CustomSoftwareDevelopment = () => {
  return (
    <div className="custom-software-page">
      {/* Header Section */}
      <Header1 />
      <br/>

      {/* Blue Panel Section */}
      <div className="bluePanel">
        <div className="heading">Custom Software Development</div>
      </div>

      {/* Content Section */}
      <main className="content">
        <p className="intro">
        <h4><strong className="highlight">Transforming Businesses with Tailored Solutions:</strong></h4>
          <br />
          At InHawk, we create custom software solutions designed to meet the
          unique needs of our clients. Our services include:
        </p>
        <section className="section">
        <strong className="highlight">University ERP Systems:</strong>
          <p>
            Our ERP systems are built for comprehensive management of both
            government and private universities.
          </p>
          <h3>Key Features:</h3>
          <ul>
            <li>
              <strong>Finance Management:</strong> Includes budget control and
              double-entry accounting on an accrual basis.
            </li>
            <li>
              <strong>Academic Management:</strong> Handles admissions,
              academics, and exam processes.
            </li>
            <li>
              <strong>Online Fee Collection:</strong> Secure, real-time fee
              payments with digital collection books.
            </li>
            <li>
              <strong>Database Compatibility:</strong> Supports Oracle 8i, 9i,
              and facilitates smooth migrations to newer versions.
            </li>
            <li>
              <strong>MIS/UGC Reporting:</strong> Generate critical reports for
              compliance and operations.
            </li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">Legacy Software Maintenance:</strong>
          <p>
            We specialize in enhancing legacy systems, extending their lifespan,
            and modernizing them for today's standards.
          </p>
          <h3>Key Services:</h3>
          <ul>
            <li>
              Migrating outdated Oracle 8i applications to the latest platforms.
            </li>
            <li>
              Upgrading VB-based systems to secure, standards-compliant web
              technologies.
            </li>
            <li>
              Ensuring seamless data and application migration to modern
              infrastructure.
            </li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">Business Process Outsourcing:</strong>
          <p>Optimize back-office operations with our BPO solutions.</p>
          <h3>Our Services Include:</h3>
          <ul>
            <li>Data and document management.</li>
            <li>
              Training and development for employees and supply chain
              stakeholders.
            </li>
            <li>
              Order management and software QA/testing for efficient operations.
            </li>
          </ul>
        </section>
      </main>

      {/* Back Button */}
      <Link to="/Services" className="backButton">
         Back 
      </Link>
    </div>
  );
};

export default CustomSoftwareDevelopment;