import React from "react";
import "./Footer.css";
import Header1 from "./Header1";

function Footer() {
  return (
    <div className="footerBG">
      <Header1 />
      <br />

      <div className="contactUs">
        {/* Title Section inside the container */}
        <div className="titleContainer">
          <h1>Contact Us</h1>
          <div className="headingLine"></div>
        </div>

        <div className="mapAndDetails">
          {/* Map Section */}
          <div className="mapSection">
            <iframe
              title="InHawk Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.523618731254!2d77.5397904!3d12.9960272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d9ec4eaeb49%3A0x40e4f2a87ddf26ad!2sInhawk%20IT%20solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1698588069142!5m2!1sen!2sin"
              className="map"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          {/* Vertical Divider */}
          <div className="verticalLine"></div>

          {/* Contact Info Section */}
          <div className="contactInfo">
            <div className="details">
              <div className="address">
                <h2>Our Address</h2>
                <p>#51, 2nd Floor, LIC Housing Colony,</p>
                <p>3rd stage, 4th Block, WCR,</p>
                <p>Basaveshwarnagar, Bengaluru-560079</p>
              </div>
              <div className="number">
                <h2>Call Us</h2>
                <p>Tel: +91-9343863400</p>
                <p>Tel: +91-9972273100</p>
              </div>
              <div className="contLink">
                <h2>Email Us</h2>
                <p>
                  <a href="mailto:anand@inhawk.com">anand@inhawk.com</a>
                </p>
                <p>
                  <a href="mailto:sales@inhawk.com">sales@inhawk.com</a>
                </p>
                <div className="social">
                  <h2>Socials</h2>
                  <a
                    href="https://www.linkedin.com/company/inhawk-it-solutions-pvt-ltd/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/linkedIn.png"
                      alt="LinkedIn Logo"
                      className="linkedImg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="watermark">© inhawk.com. All Rights Reserved.</div>
      </div>
    </div>
  );
}

export default Footer;