import React from "react";
import Header1 from "./Header1";
import "./CustomSoftware.css";

const BackupAndDisasterRecovery = () => {
  return (
    <div className="custom-software-page">
      {/* Header Section */}
      <Header1 />
      <br/>

      {/* Blue Panel Section */}
      <div className="bluePanel">
        <div className="heading">Backup and Disaster Recovery</div>
      </div>

      {/* Content Section */}
      <main className="content">
        <p className="intro">
        <h4><strong className="highlight">Safeguard Your Business from Unforeseen Events</strong></h4>
          <br />
          InHawk offers end-to-end solutions to protect your business from
          disruptions and ensure rapid recovery in the event of disasters.
        </p>
        <section className="section">
        <strong className="highlight">Backup Solutions:</strong>
          <ul>
            <li>
              <strong>Full Backups:</strong> Comprehensive snapshots of your
              system for quick restoration.
            </li>
            <li>
              <strong>Incremental Backups:</strong> Efficiently capturing only
              changes since the last backup to save time and storage.
            </li>
            <li>
              <strong>Synthetic Backups:</strong> Combining full and incremental
              backups for cost-effective storage management.
            </li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">Disaster Recovery Strategies:</strong>
          <ul>
            <li>
              Automating restoration processes to ensure minimal downtime during
              outages or disruptions.
            </li>
            <li>
              Conducting regular testing to validate recovery plans and ensure
              seamless implementation when needed.
            </li>
            <li>
              Crafting detailed recovery policies tailored to meet your specific
              business requirements.
            </li>
          </ul>
        </section>
        <section className="section">
        <strong className="highlight">Business Continuity Planning:</strong>
          <ul>
            <li>
              Integrating backup solutions into a larger framework to ensure
              ongoing operations during crises.
            </li>
            <li>
              Providing proactive maintenance and support to avoid disruptions
              before they occur.
            </li>
            <li>
              Ensuring compliance with regulatory standards and industry best
              practices.
            </li>
          </ul>
        </section>

        {/* Back Link */}
        <a href="/Services" className="backButton">
          Back 
        </a>
      </main>
    </div>
  );
};

export default BackupAndDisasterRecovery;