import React from 'react';
import "./FindOutMore.css";
import Header1 from './Header1';

function FindOutMore() {
  return (
    <div className="findmore">
      {/* Header Section */}
      <Header1 />
      
      {/* Top Banner Section */}
      <div className="top">
        <div className="findmoreBG"></div>
        <div className="whyChoose">
          <div className="title">Why Choose Us</div>
        </div>
      </div>
      
      {/* Content Body Section */}
      <div className="cont-body">
        <div>
          At InHawk, we pride ourselves on being innovators in comprehensive IT solutions. Since our establishment in Bengaluru in 2011–12, we have consistently delivered cutting-edge services across IT infrastructure, ERP solutions, software development, Oracle DBA consulting, and web applications. Our ISO 9001:2008 certification reflects our commitment to excellence, reliability, and innovation.
        </div>
        <div>
          Our vision is to be recognized as a preferred consultant and service provider, delivering innovative, reliable, and sustainable IT solutions that empower organizations to excel in the digital age. We achieve this by consistently providing state-of-the-art software, IT infrastructure, and support services tailored to meet your unique needs.
        </div>
        <div className="red">Our mission is clear:</div>
        <div>
          <div className="bullet">Deliver professional design, consulting, and custom software development services.</div>
          <div className="bullet">Build talented teams to support IT infrastructure and software solutions.</div>
          <div className="bullet">Be a trusted technology partner for organizations of all sizes.</div>
          <div className="bullet">Foster equality, respect, and inclusion while creating value for all stakeholders.</div>
        </div>
        <div>
          At the heart of InHawk lies a culture of integrity, innovation, and collaboration. These core values drive our behavior, relationships, and decisions:
        </div>
        <div>
          <div className="bullet">Innovation: We continually evolve, delivering creative and impactful solutions.</div>
          <div className="bullet">Integrity: Honesty and transparency are fundamental to everything we do.</div>
          <div className="bullet">Excellence: We exceed expectations, delivering exceptional value.</div>
          <div className="bullet">Diversity: We celebrate inclusivity and promote respect for all.</div>
        </div>
        <div>
          By combining technical expertise with deep industry understanding, we provide solutions that streamline operations, enhance efficiency, and deliver lasting value.
        </div>
        <div className="red">Choose InHawk for our:</div>
        <div>
          <div className="bullet">Proven track record of success since 2011.</div>
          <div className="bullet">Comprehensive range of IT services tailored to your needs.</div>
          <div className="bullet">Commitment to transparency, fairness, and accountability.</div>
          <div className="bullet">Expertise in crafting solutions that empower growth and innovation.</div>
        </div>
        <div>
          InHawk is not just a service provider—we are your partner in progress, helping you turn challenges into opportunities with sustainable, cutting-edge solutions.
        </div>
        <div>Let us redefine what's possible for your organization.</div>
      </div>
    </div>
  );
}

export default FindOutMore;