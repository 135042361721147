import React from "react";
import Header1 from "./Header1";
import "./CustomSoftware.css";

const ECommerceAndWebDevelopment = () => {
  return (
    <div className="custom-software-page">
      {/* Header Section */}
      <Header1 />
      <br/>

      {/* Blue Panel Section */}
      <div className="bluePanel">
        <div className="heading">E-commerce and Web Development</div>
      </div>

      {/* Content Section */}
      <main className="content">
        <p className="intro">
         <h4><strong className="highlight">Enhancing Digital Presence with Advanced Web Solutions</strong></h4> 
          <br />
          InHawk specializes in building innovative, secure, and user-friendly
          web solutions that help businesses stand out in the digital
          marketplace.
        </p>
        <section className="section">
          <strong className="highlight">Custom Web Development:</strong>
          <ul>
            <li>
              Designing web applications tailored to specific business
              requirements for various industries.
            </li>
            <li>
              Using robust technologies like .Net, Java, PHP, and others to
              create scalable, high-performance platforms.
            </li>
            <li>
              Providing integration with database systems such as Oracle, MySQL,
              and MS-SQL for seamless operations.
            </li>
          </ul>
        </section>
        <section className="section">
           <strong className="highlight">E-commerce Solutions:</strong>
          <ul>
            <li>
              Building intuitive platforms with secure payment gateways and
              custom user experiences.
            </li>
            <li>
              Delivering solutions for industries including finance, fashion,
              logistics, and more.
            </li>
            <li>
              Ensuring scalability to meet the demands of growing businesses and
              expanding markets.
            </li>
          </ul>
        </section>
        <section className="section">
           <strong className="highlight">Educational Web Applications:</strong>
          <ul>
            <li>
              Offering comprehensive solutions for admissions, examination
              management, and fee collection.
            </li>
            <li>
              Cloud-hosted platforms ensure real-time collaboration and
              accessibility for students and administrators.
            </li>
            <li>
              Enabling institutions to modernize processes while improving
              efficiency and compliance.
            </li>
          </ul>
        </section>

        {/* Back Link */}
        <a href="/Services" className="backButton">
          Back 
        </a>
      </main>
    </div>
  );
};

export default ECommerceAndWebDevelopment;